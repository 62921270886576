import { useQuery } from '@tanstack/react-query'
import { getOzoneDevicesDashboard, getSavedFilters } from 'src/services/ozone-devices/ozone-devices'
import Skeleton from 'react-loading-skeleton'
import DashboardCardInfo from 'src/components/dashboard-card-info'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import { useMemo, useState } from 'react'
import Decimal from 'decimal.js'
import TopNavigation from 'src/components/top-navigation'
import { Card } from 'src/components/ui/card'
import { HiOutlinePlus } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import DeviceHistoryDialog from 'src/components/device-ozone-history'
import { MdFullscreen } from 'react-icons/md'
import FullScreenMapDialog from 'src/components/full-screen-map-dialog'
import { DeviceSummary } from 'src/services/ozone-devices/ozone-devices.types'
import ConsolidatedMarker from 'src/components/consolidated-marker'

const calculateResponse = (total: number, devices: number) => {
  return Number(new Decimal(devices).times(100).dividedBy(total).toFixed(2))
}

type GroupedMarkers = {
  [key: string]: {
    position: {
      lat: number
      lng: number
    }
    devices: {
      deviceId: string
      status: number
      ros: number
      ts: string
      online: boolean
      live: boolean
    }[]
  }
}

const DEFAULT_CENTER = {
  lat: 25.0330,  // Taipei coordinates as default
  lng: 121.5654
}

const Dashboard = () => {
  console.log('Dashboard component version: 2025-01-06-A');

  // Helper function to determine if a device is online based on its timestamp
  const isDeviceOnline = (device: any) => {
    const lastUpdate = new Date(device.ts).getTime();
    const now = new Date().getTime();
    // Consider device online if status is 1 (normal) and updated within last 5 minutes
    return device.status === 1 && (now - lastUpdate < 5 * 60 * 1000);
  };

  const { data, isFetching } = useQuery({
    queryKey: ['ozoneDevices'],
    queryFn: getOzoneDevicesDashboard,
  })

  const { data: filters } = useQuery({
    queryKey: ['deviceFilters'],
    queryFn: getSavedFilters,
    retry: false,
  })

  const { isLoaded: isMapLoaded } = useLoadScript({
    // TODO After January 15, 2025, change the API key to be inserted from AWS Secrets Manager during build.
    googleMapsApiKey: 'AIzaSyDjtXBKPBnoTb86C4GQZ6cvvv4tRiIT8YM',
  })

  const groupedMarkers = useMemo(() => {
    console.log('Raw data from API:', data);
    if (!data?.devices) return {};
    
    console.log('First device raw:', data.devices[0]);
    
    const filtered = data.devices.filter((device: DeviceSummary) => {
      const { x, y } = device.gps;
      console.log('Device before filtering:', {
        deviceId: device.deviceId,
        gps: device.gps,
        status: device.status,
        ros: device.ros,
        ts: device.ts
      });
      return x !== "0" && y !== "0" && x && y;
    });
    
    console.log('After filtering:', filtered.length, 'devices with valid coordinates');
    console.log('First filtered device:', filtered[0]);
    
    return filtered.reduce((acc: GroupedMarkers, device: DeviceSummary) => {
      const lat = Number(device.gps.y);
      const lng = Number(device.gps.x);
      const key = `${lat},${lng}`;

      if (!acc[key]) {
        acc[key] = {
          position: { lat, lng },
          devices: []
        };
      }

      // Calculate online and live status
      const online = device.status === 1 && isDeviceOnline(device);
      const live = online && device.ros === 1;

      // Log the exact data being pushed
      const deviceData = {
        deviceId: device.deviceId,
        status: device.status,
        ros: device.ros,
        ts: device.ts,
        online,
        live
      };
      console.log('Adding device to group:', deviceData);

      acc[key].devices.push(deviceData);

      return acc;
    }, {});
  }, [data?.devices]);

  const markers = useMemo(() => {
    const result = Object.values(groupedMarkers);
    console.log('Final markers:', result.length, 'locations');
    return result;
  }, [groupedMarkers]);

  // Calculate online/offline totals using the same logic as the map
  const deviceTotals = useMemo(() => {
    if (!data?.devices) return { online: 0, offline: 0 };
    
    return data.devices.reduce((acc, device) => {
      if (isDeviceOnline(device)) {
        acc.online += 1;
      } else {
        acc.offline += 1;
      }
      return acc;
    }, { online: 0, offline: 0 });
  }, [data?.devices]);

  const totalDevicesCount = useMemo(() => 
    deviceTotals.online + deviceTotals.offline
  , [deviceTotals]);

  const mapCenter = useMemo(() => {
    if (data?.center?.x && data?.center?.y) {
      return { lat: Number(data.center.y), lng: Number(data.center.x) }  // Fix center coordinates too
    }
    return DEFAULT_CENTER
  }, [data?.center])

  return (
    <>
      <TopNavigation />
      <div className="grid overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 pb-4 gap-4">
          {!isFetching && isMapLoaded && data?.total ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 items-start content-start">
                <Link to={`/devices?online=true`}>
                  <DashboardCardInfo
                    className="h-52"
                    totalDevices={deviceTotals.online}
                    responseRate={calculateResponse(totalDevicesCount, deviceTotals.online)}
                  />
                </Link>
                <Link to={`/devices?online=false`}>
                  <DashboardCardInfo
                    isOffline
                    className="h-52"
                    totalDevices={deviceTotals.offline}
                    responseRate={calculateResponse(totalDevicesCount, deviceTotals.offline)}
                  />
                </Link>
                {filters &&
                  filters.map((filter) => (
                    <Link key={filter.filterId} to="/devices" state={{ savedFilter: filter }}>
                      <Card className="rounded-xl shadow-md h-52 flex flex-col hover:text-gray-400 transition-colors p-6">
                        <h2 className="text-2xl text-black/70 mb-10 text-left">Filter</h2>
                        <p className="text-lg text-center">{filter.filterName}</p>
                      </Card>
                    </Link>
                  ))}
                {/* <button>
                  <Card className="rounded-xl shadow-md h-52 flex items-center justify-center hover:text-gray-400 transition-colors">
                    <HiOutlinePlus size={40} />
                  </Card>
                </button> */}
              </div>
              <GoogleMap
                mapContainerClassName="rounded-xl"
                center={mapCenter}
                zoom={10}
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                <FullScreenMapDialog markers={markers.flatMap(group => 
                  group.devices.map(device => ({
                    lat: group.position.lat,
                    lng: group.position.lng,
                    online: device.online,
                    deviceId: device.deviceId,
                    live: device.live,
                    status: device.status,
                    ros: device.ros,
                    ts: device.ts
                  }))
                )} center={mapCenter}>
                  <button className="absolute right-2 top-2 p-2 bold text-white transition-colors hover:text-gray-100">
                    <MdFullscreen size={35} />
                  </button>
                </FullScreenMapDialog>
                {Object.values(groupedMarkers).map((group, index) => (
                  <DeviceHistoryDialog 
                    key={index} 
                    deviceId={group.devices[0].deviceId}
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <ConsolidatedMarker
                        position={group.position}
                        devices={group.devices}
                      />
                    </div>
                  </DeviceHistoryDialog>
                ))}
              </GoogleMap>
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 items-start">
                <div className="inline-block">
                  <Skeleton className="h-52 rounded-xl" />
                </div>
                <div className="inline-block">
                  <Skeleton className="h-52 rounded-xl" />
                </div>
              </div>
              <div className="">
                <Skeleton className="h-full rounded-xl" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Dashboard
