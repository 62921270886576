/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "ap-northeast-1",
    aws_cognito_region: "ap-northeast-1",
    aws_user_pools_id: "ap-northeast-1_shO4yyhJP",
    aws_user_pools_web_client_id: "j8jg6bgkahqrqifhu5kipka5e",
    oauth: {
        domain: "arbo3v21.auth.ap-northeast-1.amazoncognito.com",
    },
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
        ],
    },
    aws_cognito_verification_mechanisms: [],
    aws_content_delivery_bucket: "portalapp-20230827103340-hostingbucket-dev",
    aws_content_delivery_bucket_region: "ap-northeast-1",
    aws_content_delivery_url: "https://d2erzp3qnsoveg.cloudfront.net",
};

export default awsmobile;
