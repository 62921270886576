import { DashboardResponse, DeviceHistoryResponse } from './ozone-devices.types'
import axiosInstance, { setUpToken } from '../axios'
import { mockOzoneDevices } from '../mock-ozone-devices'
import { ColumnFiltersState } from '@tanstack/react-table'

export type SaveFilterParam = { filterName: string; filterId: string; filters: ColumnFiltersState }

export const getOzoneDevices = async () => {
  const { data } = await axiosInstance.get('ozone-devices')
  return data
}

export const getOzoneDevicesDashboard = async () => {
  const { data } = await axiosInstance.get<DashboardResponse>('/dashboard')
  return data
}

export const getOzoneDeviceHistory = async (deviceId: string) => {
  const { data } = await axiosInstance.get<DeviceHistoryResponse>(`devices/${deviceId}/history`)
  return data
}

export const updateDeviceOzoneById = async ({ id, ozoneLevel }: { id: string; ozoneLevel: number | string }) => {
  const { data } = await axiosInstance.patch<any>(`devices/${id}`, { ozoneLevel })
  return data
}

export const getSavedFilters = () => {
  const filters = localStorage.getItem('ozoneFilters')
  return JSON.parse(filters || '[]') as SaveFilterParam[]
}

export const saveFilter = (filter: SaveFilterParam) => {
  const promise = new Promise<SaveFilterParam>((resolve) => {
    const filters = getSavedFilters()
    localStorage.setItem('ozoneFilters', JSON.stringify([...filters, filter]))
    resolve(filter)
  })
  return promise
}

export const deleteFilter = async (filterId: string) => {
  return true
}
