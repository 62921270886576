import { PropsWithChildren, useCallback, useState, useEffect, useMemo } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getOzoneDeviceHistory, updateDeviceOzoneById } from '../../services/ozone-devices/ozone-devices'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import Skeleton from 'react-loading-skeleton'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, YAxis, CartesianGrid } from 'recharts'
import BatteryIcon from '../../svg/battery-icon.svg'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select'
import { STATUS_LABELS } from 'src/lib/constants'
import { Slider } from '../ui/slider'
import { format } from 'date-fns'
import { Button } from '../ui/button'

const statusLabelOptions = Object.keys(STATUS_LABELS).map((key) => ({
  value: key,
  label: STATUS_LABELS[key as keyof typeof STATUS_LABELS].label,
}))

interface DeviceHistoryDialogProps {
  open?: boolean
  deviceId: string
}

const CustomTooltip = ({ active, payload, label }: { active?: boolean; payload?: any[]; label?: string }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white h-16 w-16 text-center leading-none rounded-full flex items-center justify-center flex-col">
        <p className="text-black font-semibold text-3xl -mb-1">{payload[0].value}</p>
        <p className="text-black font-semibold">ppb</p>
      </div>
    )
  }

  return null
}

const DeviceHistoryDialog = ({ deviceId, children }: PropsWithChildren<DeviceHistoryDialogProps>) => {
  const { t } = useTranslation()
  const [timeRange, setTimeRange] = useState<'24h' | 'week'>('24h')
  const [pendingOzoneLevel, setPendingOzoneLevel] = useState<number | null>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [lastChangeTimestamp, setLastChangeTimestamp] = useState<number | null>(null)
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['deviceHistory', deviceId],
    queryFn: () => getOzoneDeviceHistory(deviceId),
    enabled: false,
    retry: false,
  })

  const updateOzoneLevel = useMutation({
    mutationFn: updateDeviceOzoneById,
    retry: 3,
    retryDelay: 1000,
    onSuccess: () => {
      setPendingOzoneLevel(null)
      setShowConfirmation(false)
      refetch()
    },
    onError: (error) => {
      console.error('Failed to update ozone level:', error)
      setPendingOzoneLevel(null)
      setShowConfirmation(false)
    }
  })

  useEffect(() => {
    if (lastChangeTimestamp) {
      const timer = setTimeout(() => {
        if (pendingOzoneLevel !== null && pendingOzoneLevel !== data?.ozoneLevel) {
          setShowConfirmation(true)
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [lastChangeTimestamp, pendingOzoneLevel, data?.ozoneLevel])

  const fetchHistory = useCallback(
    (open: boolean) => {
      if (open) refetch()
    },
    [refetch],
  )

  const handleOzoneLevelChange = (value: number[]) => {
    const newValue = value[0]
    if (newValue !== data?.ozoneLevel) {
      setPendingOzoneLevel(newValue)
      setShowConfirmation(false)
      setLastChangeTimestamp(Date.now())
    }
  }

  const handleUpdateConfirm = () => {
    if (pendingOzoneLevel !== null && data && pendingOzoneLevel !== data.ozoneLevel) {
      updateOzoneLevel.mutate({ id: deviceId, ozoneLevel: pendingOzoneLevel })
    }
  }

  const handleUpdateCancel = () => {
    setPendingOzoneLevel(null)
    setShowConfirmation(false)
  }

  const formatXAxisTick = (value: string) => {
    const date = new Date(value)
    if (timeRange === '24h') {
      return format(date, 'HH:mm')
    }
    return format(date, 'MM/dd')
  }

  const processedHistory = useMemo(() => {
    if (!data?.history) return []
    
    const now = new Date()
    const cutoffDate = new Date(now)
    
    if (timeRange === '24h') {
      cutoffDate.setHours(now.getHours() - 24)
      return data.history.filter(entry => new Date(entry.reference) >= cutoffDate)
    } else {
      cutoffDate.setDate(now.getDate() - 7)
      
      const dailyData = data.history
        .filter(entry => new Date(entry.reference) >= cutoffDate)
        .reduce((acc: { [key: string]: typeof data.history[0][] }, entry) => {
          const date = format(new Date(entry.reference), 'yyyy-MM-dd')
          if (!acc[date]) acc[date] = []
          acc[date].push(entry)
          return acc
        }, {})
      
      return Object.values(dailyData).map(entries => entries[entries.length - 1])
    }
  }, [data?.history, timeRange])

  return (
    <Dialog onOpenChange={fetchHistory}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[85%] md:max-w-[75%] lg:max-w-[60%] max-h-[90vh] overflow-y-auto">
        <div className="grid gap-4 p-1">
          {!data || isFetching ? (
            <Skeleton className="h-[500px] rounded-2xl" />
          ) : (
            <>
              <div className="rounded-2xl shadow-md h-[500px] overflow-hidden">
                <div className="absolute pl-6 pt-4 text-white w-full flex justify-between">
                  <div className="flex flex-col font-semibold">
                    <div className="text-4xl">{data.description}</div>
                    <div className="text-3xl">{t('dashboard.devicesTable.deviceOzoneSettingModal.currentOzoneLevel')}</div>
                    <div className="text-[#2DFFFF] text-8xl">{data.ozoneLevel}</div>
                  </div>
                  <div className="flex flex-col items-end gap-4 mr-6">
                    <div className="flex items-center gap-8">
                      <div>
                        <label className="block mb-1 font-semibold text-lg">
                          {t('dashboard.devicesTable.deviceOzoneSettingModal.timeRange.label')}
                        </label>
                        <Select defaultValue="24h" value={timeRange} onValueChange={(value: '24h' | 'week') => setTimeRange(value)}>
                          <SelectTrigger className="w-44 bg-transparent text-white border-white text-base h-8 relative z-10">
                            <SelectValue defaultValue="24h" placeholder={t('dashboard.devicesTable.deviceOzoneSettingModal.timeRange.last24Hours')} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="24h">
                                {t('dashboard.devicesTable.deviceOzoneSettingModal.timeRange.last24Hours')}
                              </SelectItem>
                              <SelectItem value="week">
                                {t('dashboard.devicesTable.deviceOzoneSettingModal.timeRange.lastWeek')}
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <img src={BatteryIcon} alt="card icon" className="pt-1 w-24 h-auto" />
                    </div>
                  </div>
                </div>
                <ResponsiveContainer width="100%" height="100%" className="bg-[#0B2D5E]">
                  <LineChart
                    width={600}
                    height={500}
                    data={processedHistory}
                    margin={{
                      top: 200,
                      right: 40,
                      left: 40,
                      bottom: 40,
                    }}
                  >
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="ozoneLevel"
                      stroke="#FFFFFF"
                      dot={false}
                      strokeWidth={4}
                      activeDot={{ stroke: 'white', fill: '#0B2D5E', r: 7 }}
                    />
                    <XAxis
                      dataKey="reference"
                      tickLine={false}
                      tick={{ stroke: 'white', fill: 'white' }}
                      axisLine={{ stroke: 'white' }}
                      interval={timeRange === '24h' ? Math.floor(data.history.length / 6) : 'preserveStartEnd'}
                      padding="gap"
                      tickFormatter={formatXAxisTick}
                    />
                    <YAxis
                      tickLine={{ stroke: 'white' }}
                      tick={{ stroke: 'white', fill: 'white' }}
                      axisLine={{ stroke: 'white' }}
                      ticks={[0, 25, 50, 75, 100]}
                      domain={[0, 100]}
                      padding={{ top: 20, bottom: 20 }}
                    />
                    <CartesianGrid 
                      stroke="rgba(255, 255, 255, 0.2)" 
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center py-2 px-6">
                <div className="col-span-8">
                  <h2 className="text-gray-400 text-xl font-bold mb-2">{t('dashboard.devicesTable.deviceOzoneSettingModal.setTargetOzoneLevel')}</h2>
                  <div className="bg-[#E1F4FF] p-3 rounded-lg">
                    <div className="text-center text-2xl font-bold text-gray-500 mb-2">
                      {pendingOzoneLevel !== null ? pendingOzoneLevel : data.ozoneLevel}
                    </div>
                    <Slider
                      className="slider mb-2 w-full max-w-[300px] mx-auto"
                      defaultValue={[data.ozoneLevel]}
                      value={pendingOzoneLevel !== null ? [pendingOzoneLevel] : [data.ozoneLevel]}
                      min={0}
                      max={100}
                      step={1}
                      onValueChange={handleOzoneLevelChange}
                    />
                    {showConfirmation && (
                      <div className="flex justify-end gap-2 mt-2">
                        <Button 
                          variant="outline" 
                          onClick={handleUpdateCancel}
                          className="h-8 px-3 text-sm bg-white hover:bg-gray-50"
                        >
                          {t('common.actions.cancel')}
                        </Button>
                        <Button 
                          onClick={handleUpdateConfirm}
                          className="h-8 px-3 text-sm bg-blue-600 hover:bg-blue-700 text-white"
                        >
                          {t('common.actions.save')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-4">
                  <h2 className="pb-6 font-bold text-2xl text-gray-400">Mode:</h2>
                  <Select defaultValue={'2'}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select a mode" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Mode</SelectLabel>
                        {statusLabelOptions.map((statusOption) => (
                          <SelectItem key={statusOption.value} value={statusOption.value}>
                            {statusOption.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeviceHistoryDialog
