import { useState } from 'react'
import { OverlayView } from '@react-google-maps/api'

interface ConsolidatedMarkerProps {
  position: {
    lat: number
    lng: number
  }
  devices: {
    deviceId: string
    status: number
    ros: number
    ts: string
  }[]
  onClick?: () => void
}

const ConsolidatedMarker = ({ position, devices, onClick }: ConsolidatedMarkerProps) => {
  const [isHovered, setIsHovered] = useState(false)

  // Helper function to determine if a device is online based on its timestamp
  const isDeviceOnline = (timestamp: string) => {
    const lastUpdate = new Date(timestamp).getTime()
    const now = new Date().getTime()
    // Consider device online if updated within last 5 minutes
    return now - lastUpdate < 5 * 60 * 1000
  }

  // Determine marker color based on device statuses
  const hasLive = devices.some(d => d.status === 1 && isDeviceOnline(d.ts) && d.ros === 1)
  const hasOnline = devices.some(d => d.status === 1 && isDeviceOnline(d.ts))
  const backgroundColor = hasLive ? '#73CDF8' : hasOnline ? '#037B01' : '#DE0A13'

  const handleMouseEnter = () => {
    console.log('Mouse enter, devices:', devices)
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    console.log('Mouse leave')
    setIsHovered(false)
  }

  return (
    <>
      {/* Circle Marker */}
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -(height / 2)
        })}
      >
        <div
          className="relative cursor-pointer z-10"
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div 
            className="rounded-full flex items-center justify-center shadow-lg border-2 border-white"
            style={{
              backgroundColor,
              width: devices.length > 1 ? '32px' : '24px',
              height: devices.length > 1 ? '32px' : '24px',
              transition: 'all 0.2s ease-in-out'
            }}
          >
            {devices.length > 1 && (
              <span className="text-white font-bold text-sm">
                {devices.length}
              </span>
            )}
          </div>
        </div>
      </OverlayView>

      {/* Tooltip */}
      {isHovered && (
        <OverlayView
          position={position}
          mapPaneName={OverlayView.FLOAT_PANE}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: 80
          })}
        >
          <div 
            className="bg-white px-4 py-3 rounded-lg shadow-xl text-sm min-w-[200px] border-2 border-gray-300"
            style={{ 
              transform: 'translateY(20px)',
              zIndex: 1000
            }}
          >
            {devices.map((device, index) => {
              const online = device.status === 1 && isDeviceOnline(device.ts)
              const live = online && device.ros === 1

              return (
                <div key={index} className="text-center py-1.5">
                  <div className="font-semibold text-gray-900">
                    ID: {device.deviceId}
                  </div>
                  <div className="text-xs text-gray-500">
                    Status: {online ? 'Online' : 'Offline'}, Live: {live ? 'Yes' : 'No'}
                  </div>
                </div>
              )
            })}
            <div className="mt-1 pt-1 border-t border-gray-200 text-center text-xs text-gray-500">
              {devices.length} device{devices.length !== 1 ? 's' : ''}
            </div>
          </div>
        </OverlayView>
      )}
    </>
  )
}

export default ConsolidatedMarker 