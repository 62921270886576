import { PropsWithChildren } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import DeviceHistoryDialog from '../device-ozone-history'
import { getMarker } from 'src/lib/ui-utils'

interface FullScreenMapDialogProps {
  markers?: {
    lat: number
    lng: number
    online: boolean
    deviceId: string
    live: boolean
    status: number
    ros: number
    ts: string
  }[]
  center?: {
    lat: number
    lng: number
  }
}

const DEFAULT_CENTER = {
  lat: 25.0330,
  lng: 121.5654
}

const FullScreenMapDialog = ({ markers, children, center = DEFAULT_CENTER }: PropsWithChildren<FullScreenMapDialogProps>) => {
  // Helper function to determine if a device is online based on its timestamp
  const isDeviceOnline = (timestamp: string) => {
    const lastUpdate = new Date(timestamp).getTime()
    const now = new Date().getTime()
    // Consider device online if updated within last 5 minutes
    return now - lastUpdate < 5 * 60 * 1000
  }

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="min-w-full left-0 top-0 transform-none min-h-screen"
        closeClassName="right-4 top-4 sm:right-4 sm:top-4"
      >
        <GoogleMap
          mapContainerClassName="rounded-xl"
          center={center}
          zoom={10}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {markers?.map((marker, index) => {
            const online = marker.status === 1 && isDeviceOnline(marker.ts)
            const live = online && marker.ros === 1

            return (
              <DeviceHistoryDialog key={index} deviceId={marker.deviceId}>
                <MarkerF
                  icon={getMarker(live ? '#73CDF8' : online ? '#037B01' : '#DE0A13')}
                  position={{ lat: marker.lat, lng: marker.lng }}
                />
              </DeviceHistoryDialog>
            )
          })}
        </GoogleMap>
      </DialogContent>
    </Dialog>
  )
}

export default FullScreenMapDialog
