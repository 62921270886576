import axios from 'axios'
import { Auth } from 'aws-amplify'

const axiosInstance = axios.create({
  baseURL: 'https://dev-api.arbo3.com',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  // Force axios to treat this as a cross-origin request
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN'
})

axiosInstance.interceptors.request.use(async (config) => {
  const token = await setUpToken()
  config.headers['Authorization'] = token
  return config
})

export const setUpToken = async () => {
  const authenticatedUser = await Auth.currentSession()

  const token = authenticatedUser.getIdToken().getJwtToken()
  return `Bearer ${token}`
}

export default axiosInstance
