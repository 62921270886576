import { Column, Table } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import DebouncedInput from '../ui/debounceInput'

export function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
  const columnFilterValue = column.getFilterValue()

  const { t } = useTranslation('common')

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={t('actions.search', 'Search...', {})}
        className="w-full sm:w-3/4 border shadow rounded m-auto"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}

export function TypeAheadFilter({
  column,
  table,
  options,
}: {
  column: Column<any, unknown>
  table: Table<any>
  options: Array<string>
}) {
  const { t } = useTranslation('common')
  const [inputValue, setInputValue] = useState('')
  const filterValue = column.getFilterValue() as string

  const filteredOptions = useMemo(() => {
    if (!inputValue) return options
    return options.filter(option => 
      option.toLowerCase().includes(inputValue.toLowerCase())
    )
  }, [options, inputValue])

  return (
    <div className="w-full sm:w-3/4 m-auto">
      <Select
        inputValue={inputValue}
        onInputChange={setInputValue}
        value={filterValue ? { value: filterValue, label: filterValue } : null}
        placeholder={t('actions.search')}
        options={filteredOptions.map(option => ({ value: option, label: option }))}
        onChange={(selected) => {
          column.setFilterValue(selected ? selected.value : undefined)
        }}
        className="basic-select"
        classNamePrefix="select"
        isClearable
        isSearchable
      />
      <div className="h-1" />
    </div>
  )
}

export function MultiSelectFilter({
  column,
  table,
  options,
}: {
  column: Column<any, unknown>
  table: Table<any>
  options: Array<string>
}) {
  const { t } = useTranslation('common')
  const filterValue = useMemo(
    () =>
      ((column.getFilterValue() as Array<string>) || []).map((option) => ({
        value: option,
        label: option,
      })),
    [column.getFilterValue()],
  )

  const formattedOptions = useMemo(
    () =>
      options.map((option) => ({
        value: option,
        label: option,
      })),
    [options],
  )

  return (
    <div className="w-full sm:w-3/4 m-auto">
      <Select
        value={filterValue}
        isMulti
        placeholder={t('actions.select')}
        name="statusFilter"
        options={formattedOptions}
        onChange={(selected) => {
          const values = selected.map((s) => (s as { value: string }).value)
          column.setFilterValue(values.length ? values : undefined)
        }}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <div className="h-1" />
    </div>
  )
}
