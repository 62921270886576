import { AiOutlineLogout } from 'react-icons/ai'
import { Auth } from 'aws-amplify'
import { Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from 'src/App'
import { useTranslation } from 'react-i18next'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import i18next from 'i18next'

async function signOut() {
  try {
    await Auth.signOut()
  } catch (error) {
    console.log('error signing out: ' + error)
  }
}

// const logUserInfo = (user) => {
//   console.log(user);
// };

export const Layout = () => {
  const user = useContext(UserContext)
  const { t } = useTranslation()

  return (
    <section className="mx-4 py-2 sm:px-4 sm:py-2 md:px-4 md:py-4 lg:px-6 lg:py-6 flex flex-col h-screen">
      <div className="w-full flex items-center justify-between pb-4">
        <img
          alt="Arbo3"
          src="/images/TCC-Logo.png"
          className="h-[20px] sm:h-[30px] md:h-[40px] lg:h-[50px] object-[50% 50%]"
        />
        <img
          alt="Arbo3"
          src="/images/arbo3.svg"
          className="h-[20px] sm:h-[30px] md:h-[40px] lg:h-[50px] object-[50% 50%]"
        />

        <p className="text-white flex items-center">
          {t('layout.greeting')}, {user?.username}
          <button onClick={signOut} className="px-3 py-1">
            {/* Temporal icon, will replace with the one from figma */}
            <AiOutlineLogout color="white" size="2em" className="cursor-pointer" />
          </button>
        </p>
      </div>

      <main className="bg-white h-full rounded-3xl px-8 overflow-hidden grid" style={{ gridTemplateRows: 'auto 1fr' }}>
        <Outlet />
      </main>

      <div className="text-white py-0 sm:pt-2 md:pt-4 flex justify-between items-center">
        <span>
          <span>{t('footer.copyright')}</span>
          <a href="https://www.arbo3.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300">
            www.arbo3.com
          </a>
        </span>
        <Select
          onValueChange={(lang) => {
            i18next.changeLanguage(lang)
          }}
          defaultValue={i18next.language}
        >
          <SelectTrigger className="w-auto text-black">
            <SelectValue placeholder="Lang" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="en-US">🇺🇸</SelectItem>
            <SelectItem value="zh-TW">🇹🇼</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </section>
  )
}
